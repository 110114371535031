import { combineReducers } from "redux";
import { Action, handleActions } from "redux-actions";
import { connectRouter, RouterState } from "connected-react-router";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { History, createBrowserHistory } from "history";
import { reducer as ModalReducer, ReduxModalState } from "redux-modal";
import AuthenticationReducer, {
  AuthenticationState,
  initialState as initAuthState,
} from "./authentication";
import GeneralReducer, {
  GeneralState,
  initialState as initGeneralState,
} from "./general";
import FilesReducer, {
  FilesState,
  initialState as initFilesState,
} from "./files";
import ProfileReducer, {
  ProfileState,
  initialState as initProfileState,
} from "./profile";

export interface State {
  router: RouterState;
  modal: ReduxModalState;
  authentication: AuthenticationState;
  general: GeneralState;
  files: FilesState;
  profile: ProfileState;
}

const initState = {
  authentication: initAuthState,
  general: initGeneralState,
  files: initFilesState,
  profile: initProfileState,
};

export const history = createBrowserHistory();
const appReducer = combineReducers({
  router: connectRouter(history),
  modal: ModalReducer,
  authentication: AuthenticationReducer,
  general: GeneralReducer,
  files: FilesReducer,
  profile: ProfileReducer,
});

const rootReducer = (state: any, action: Action<any>) => {
  if (action.type === "RESET") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    state = { ...state, ...initState };
  }
  return appReducer(state, action);
};

export default rootReducer;
