import { Action, handleActions } from "redux-actions";
import { combineReducers } from "redux";
import BioStorageReducer, {
  BioStorageState,
  initialState as initBioState,
} from "./biostorage";
import MatchReducer, {
  MatchState,
  initialState as initMatchState,
} from "./match";
import CustomersReducer, {
  CustomersState,
  initialState as initCustomerState,
} from "./customers";
import MerchantsReducer, {
  MerchantsState,
  initialState as initMerchantState,
} from "./merchants";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";
import moment from "moment";
import getBioProducts from "../../actions/getBioProducts";
import getMatchProducts from "../../actions/getMatchProducts";
import getCustomers from "../../actions/getCustomers";
import goToCustomer from "../../actions/goToCustomer";

// State Interface
interface YearState {
  isRequesting: boolean;
  yearList: number[];
  selectedYear: number;
  error?: any;
}

const currentYear = moment().year();
const lastYear = currentYear - 1;

// Initial state
const initialYearState = {
  isRequesting: false,
  yearList: [lastYear, currentYear],
  selectedYear: lastYear,
  error: undefined,
} as YearState;

// Actions
const YEAR_REQUEST = "YEAR_REQUEST";
const YEAR_ERROR = "YEAR_ERROR";
const YEAR_SUCCESS = "YEAR_SUCCESS";
const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";

// Action creators
export function requestYears() {
  return {
    type: YEAR_REQUEST,
  };
}

export function errorYears(error: any) {
  return {
    type: YEAR_ERROR,
    payload: { error },
  };
}

export function receiveYears(data: any) {
  return {
    type: YEAR_SUCCESS,
    payload: data,
  };
}

export function setSelectedYear(year: number) {
  return {
    type: SET_SELECTED_YEAR,
    payload: year,
  };
}

export function updateSelectedYear(year?: number) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const newYear: number = year ? year : state.general.year.selectedYear;
    if (state.router.location.pathname === "/customer")
      dispatch(goToCustomer());
    dispatch(setSelectedYear(newYear));
    dispatch(getBioProducts());
    dispatch(getMatchProducts());
    dispatch(getCustomers());
  };
}

// Reducer Definition
const GeneralReducer = handleActions<YearState>(
  {
    [YEAR_REQUEST]: handleYearsRequest,
    [YEAR_ERROR]: handleYearsError,
    [YEAR_SUCCESS]: handleYearsSuccess,
    [SET_SELECTED_YEAR]: handleSetSelectedYear,
  } as any,
  initialYearState
);

// Reducer Description
function handleYearsRequest(state: YearState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleYearsError(state: YearState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleYearsSuccess(state: YearState, action: Action<any>) {
  return {
    ...state,
    yearList: action.payload.tax_years,
    isRequesting: false,
    error: undefined as any,
  };
}

function handleSetSelectedYear(state: YearState, action: Action<any>) {
  return {
    ...state,
    isRequesting: false,
    selectedYear: action.payload,
    error: undefined as any,
  };
}

// State Interface
export interface GeneralState {
  year: YearState;
  biostorage: BioStorageState;
  match: MatchState;
  customers: CustomersState;
  merchants: MerchantsState;
}

export const initialState = {
  year: initialYearState,
  biostorage: initBioState,
  match: initMatchState,
  customers: initCustomerState,
  merchants: initMerchantState,
} as GeneralState;

export default combineReducers({
  year: GeneralReducer,
  biostorage: BioStorageReducer,
  match: MatchReducer,
  customers: CustomersReducer,
  merchants: MerchantsReducer,
});
