/**
 * Regular MUI Button variation: Rounded
 */

import { withStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ButtonRounded = withStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  contained: {
    boxShadow: "none",
  },
}))(Button);

export default ButtonRounded;
