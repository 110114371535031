/**
 *  Dashboard main page
 */
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ButtonRounded from "../../components/ButtonRounded";
import InfoBox from "../../components/InfoBox";
import CustomersTable from "./CustomersTable";
import Box from "@material-ui/core/Box";
import SmartTable from "../../components/SmartTable";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import AlertPopover from "../../components/AlertPopover";
import YearSelect from "../../components/YearSelect";
import { FormattedMessage } from "react-intl";
import UploadButton from "../../components/UploadButton";
import moment from "moment";
import { BASE_URL } from "../../api/endpoints";
import { isAuditor } from "../../api/utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: { display: "flex", margin: theme.spacing(5) },
      card: {
        height: "100%",
        display: "flex",
        padding: theme.spacing(2),
      },
      bold: { fontWeight: "bold" },
      input: {
        display: "none",
      },
      cardContent: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(2),
      },
      greyBox: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: theme.spacing(9),
        backgroundColor: theme.palette.grey[50],
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
      },
      fileName: { fontWeight: "bold" },
      iconDelete: {
        color: theme.palette.error.main,
      },
      linkDownload: {
        color: "inherit",
        height: "24px",
        width: "24px",
      },
      noData: {
        flex: 1,
        marginTop: theme.spacing(3),
        minHeight: "150px",
        border: `1px solid ${theme.palette.grey[200]}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      noDataText: {
        color: theme.palette.grey[300],
      },
    })
);

interface Props {
  selectedYear: number;
  bioList: any;
  matchList: { [id: string]: any };
  fileList: { [id: string]: any };
  merchantName: string;
  uploadFile: (file: File) => void;
  updateSelectedYear: (year?: number) => void;
  openModalDelete: any;
  openModalReport: any;
}

const MerchantView: React.FC<Props> = ({
                                         selectedYear,
                                         bioList,
                                         matchList,
                                         fileList,
                                         merchantName,
                                         uploadFile,
                                         openModalDelete,
                                         openModalReport,
                                       }) => {
  const classes = useStyles();

  const handleUpload = (files: File[]) => {
    uploadFile(files[0]);
  };

  const file =
      Array.isArray(fileList) &&
      fileList.filter(file => file.tax_year === selectedYear)[0];

  const status: {
    isValid: boolean;
    isExtracting: boolean;
    isAdding: boolean;
  } = { isValid: true, isExtracting: false, isAdding: false };

  const getUpdatedMatchList = () => {
    let newList: { [id: string]: any } = {};
    Object.keys(matchList).map((bio_id: string) => {
      let trend = "ok";
      if (bioList[bio_id] && bioList[bio_id].trend === "up") {
        status.isAdding = true;
      } else if (bioList[bio_id] && bioList[bio_id].trend === "down") {
        status.isExtracting = true;
 } else if (
          !bioList[bio_id] ||
          matchList[bio_id].volume_sold > matchList[bio_id].volume_bought
      ) {
        if (matchList[bio_id].fossil_100) {
          trend = "allowed";
        } else {
          trend = "notOk";
          status.isValid = false;
        }
      }
      newList = {
        ...newList,
        [bio_id]: { ...matchList[bio_id], trend },
      };
    });
    return newList;
  };
  const updatedMatchList = getUpdatedMatchList();
  const updatedMatchTable = Object.values(updatedMatchList);

  return (
      <div className={classes.root}>
        <Grid container justify="center" alignItems="stretch" spacing={3}>
          <Grid item xs={8} style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2" className={classes.bold}>
              <FormattedMessage id="merchantView.title" />
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Box display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="h5">
                      <FormattedMessage id="merchantView.chooseYear" />
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <YearSelect fullWidth />
                  </Box>
                </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5">
                <FormattedMessage id="merchantView.matchingVsSold" />
                {" " + selectedYear}
              </Typography>
              { updatedMatchTable.length > 0 ? (
                <Grid container alignItems="stretch" spacing={3}>
                  <Grid item xs={9}>
                    <SmartTable
                      headCells={[
                        {
                          name: (
                            <FormattedMessage id="merchantView.table.match.product" />
                          ),
                          id: "product_name",
                        },
                        {
                          name: (
                            <FormattedMessage id="merchantView.table.match.volumeBought" />
                          ),
                          id: "volume_bought",
                          rightAlign: true,
                        },
                        {
                          name: (
                            <FormattedMessage id="merchantView.table.match.volumeSold" />
                          ),
                          id: "volume_sold",
                          rightAlign: true,
                        },
                      ]}
                      hideIndications={!file}
                      matchIsOk={status.isValid}
                      list={updatedMatchTable}
                    ></SmartTable>
                  </Grid>
                  <Grid item xs={3}>
                    {file && <InfoBox status={status} />}
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.noData}>
                  <Typography variant="body1" className={classes.noDataText}>
                    <FormattedMessage id="merchantView.noData" />
                  </Typography>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="merchantView.reportFromPreem" />
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="merchantView.reportSubText" />
                </Typography>
              </div>

              <ButtonRounded
                fullWidth
                variant="contained"
                color="primary"
                onClick={openModalReport}
                disabled={moment().isBefore(selectedYear + 1 + "-03-31")}
              >
                <FormattedMessage id="merchantView.downloadReport" />
              </ButtonRounded>
            </CardContent>
          </Card>
        </Grid>
          <Grid item xs={8}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Box display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="h5">
                      <FormattedMessage id="merchantView.uploadFile" />
                    </Typography>
                  </Box>
                  { file && file.product_lists.length > 0 && (
                      <Box>
                        <AlertPopover productNumbers={file.product_lists} />
                      </Box>
                  )}
                </Box>

                {file ? (
                    <Box className={classes.greyBox}>
                      <Box flex={1}>
                        <Typography variant="body2" className={classes.fileName}>
                          {file.file_name}
                        </Typography>
                      </Box>
                      <Box width={80}>
                        <IconButton color="primary">
                          <a
                              className={classes.linkDownload}
                              href={`${BASE_URL}${file.file_path}`}
                              download
                          >
                            <GetAppIcon />
                          </a>
                        </IconButton>
                        <IconButton
                            className={classes.iconDelete}
                            onClick={openModalDelete}
                            disabled={isAuditor()}
                            size="small"
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                ) : (
                    <Box className={classes.greyBox}>
                      <Typography variant="body2">
                        <FormattedMessage id="merchantView.noFiles" />
                      </Typography>
                    </Box>
                )}
                <UploadButton
                    handleFiles={handleUpload}
                    disabled={file || isAuditor()}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <CustomersTable isValid={status.isValid} />
          </Grid>
        </Grid>
      </div>
  );
};

export default MerchantView;
