import React from "react";
import { store } from "../reducers/store";
import { goBack } from "connected-react-router";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      textTransform: "none",
    },
  })
);

interface ButtonArrowBackProps {
  className?: string;
}

const ButtonArrowBack: React.FC<ButtonArrowBackProps> = ({
  className = "",
}) => {
  const classes = useStyles();

  return (
    <Button
      color="primary"
      className={`${classes.backBtn} ${className}`}
      onClick={() => store.dispatch(goBack())}
    >
      <Icon color="primary" fontSize="small">
        arrow_back
      </Icon>
      <Typography variant="body1">
        &nbsp; <FormattedMessage id="button.back" />
      </Typography>
    </Button>
  );
};

export default ButtonArrowBack;
