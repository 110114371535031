import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";

// State Interface
export interface BioStorageState {
  isRequesting: boolean;
  bioList: any[];
  error?: any;
}

// Initial state
export const initialState = {
  isRequesting: false,
  bioList: {},
  error: undefined,
} as BioStorageState;

// Actions
const BIOSTORAGE_REQUEST = "BIOSTORAGE_REQUEST";
const BIOSTORAGE_ERROR = "BIOSTORAGE_ERROR";
const BIOSTORAGE_SUCCESS = "BIOSTORAGE_SUCCESS";

// Action creators
export function requestBiostorage() {
  return {
    type: BIOSTORAGE_REQUEST,
  };
}

export function errorBiostorage(error: any) {
  return {
    type: BIOSTORAGE_ERROR,
    payload: { error },
  };
}

export function receiveBiostorage(data: any) {
  return {
    type: BIOSTORAGE_SUCCESS,
    payload: data,
  };
}

// Reducer Definition
export default handleActions<BioStorageState>(
  {
    [BIOSTORAGE_REQUEST]: handleBiostorageRequest,
    [BIOSTORAGE_ERROR]: handleBiostorageError,
    [BIOSTORAGE_SUCCESS]: handleBiostorageSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleBiostorageRequest(state: BioStorageState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleBiostorageError(
  state: BioStorageState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleBiostorageSuccess(state: BioStorageState, action: Action<any>) {
  return {
    ...state,
    bioList: action.payload.entities.biostorage_products
      ? action.payload.entities.biostorage_products
      : {},
    isRequesting: false,
    error: undefined as any,
  };
}
