import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";

// State Interface
export interface FilesState {
  isRequesting: boolean;
  fileList: any[];
  error?: any;
}

// Initial state
export const initialState = {
  fileList: [],
  isRequesting: false,
  error: undefined,
} as FilesState;

// Actions
const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";

const FILE_STATUS_REQUEST = "FILE_STATUS_REQUEST";
const FILE_STATUS_ERROR = "FILE_STATUS_ERROR";
const FILE_STATUS_SUCCESS = "FILE_STATUS_SUCCESS";

const FILE_LIST_REQUEST = "FILE_LIST_REQUEST";
const FILE_LIST_ERROR = "FILE_LIST_ERROR";
const FILE_LIST_SUCCESS = "FILE_LIST_SUCCESS";

const FILE_DELETE_REQUEST = "FILE_DELETE_REQUEST";
const FILE_DELETE_ERROR = "FILE_DELETE_ERROR";
const FILE_DELETE_SUCCESS = "FILE_DELETE_SUCCESS";

// Action creators
export function uploadRequest() {
  return {
    type: FILE_UPLOAD_REQUEST,
  };
}

export function uploadError(error: any) {
  return {
    type: FILE_UPLOAD_ERROR,
    payload: { error },
  };
}

export function uploadSuccess(data: any) {
  return {
    type: FILE_UPLOAD_SUCCESS,
    payload: data,
  };
}

// Reducer Definition
export default handleActions<FilesState>(
  {
    [FILE_UPLOAD_REQUEST]: handleFileUploadRequest,
    [FILE_UPLOAD_ERROR]: handleFileUploadError,
    [FILE_UPLOAD_SUCCESS]: handleFileUploadSuccess,
    [FILE_STATUS_REQUEST]: handleFileStatusRequest,
    [FILE_STATUS_ERROR]: handleFileStatusError,
    [FILE_STATUS_SUCCESS]: handleFileStatusSuccess,
    [FILE_LIST_REQUEST]: handleFileListRequest,
    [FILE_LIST_ERROR]: handleFileListError,
    [FILE_LIST_SUCCESS]: handleFileListSuccess,
    [FILE_DELETE_REQUEST]: handleFileDeleteRequest,
    [FILE_DELETE_ERROR]: handleFileDeleteError,
    [FILE_DELETE_SUCCESS]: handleFileDeleteSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleFileUploadRequest(state: FilesState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleFileUploadError(
  state: FilesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleFileUploadSuccess(state: FilesState, action: Action<any>) {
  return {
    ...state,
    error: undefined as any,
  };
}

function handleFileStatusRequest(state: FilesState, action: Action<any>) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleFileStatusError(
  state: FilesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleFileStatusSuccess(state: FilesState, action: Action<any>) {
  return {
    ...state,
    error: undefined as any,
  };
}

function handleFileListRequest(state: FilesState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleFileListError(state: FilesState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleFileListSuccess(state: FilesState, action: Action<any>) {
  return {
    ...state,
    fileList: action.payload.sales_groups,
    isRequesting: false,
    error: undefined as any,
  };
}

function handleFileDeleteRequest(state: FilesState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleFileDeleteError(
  state: FilesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleFileDeleteSuccess(state: FilesState, action: Action<any>) {
  return {
    ...state,
    fileList: action.payload,
    isRequesting: false,
    error: undefined as any,
  };
}
