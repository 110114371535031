import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";

// State Interface
export interface MatchState {
  isRequesting: boolean;
  matchList: any[];
  error?: any;
}

// Initial state
export const initialState = {
  isRequesting: false,
  matchList: [],
  error: undefined,
} as MatchState;

// Actions
const MATCH_REQUEST = "MATCH_REQUEST";
const MATCH_ERROR = "MATCH_ERROR";
const MATCH_SUCCESS = "MATCH_SUCCESS";

// Action creators
export function requestMatch() {
  return {
    type: MATCH_REQUEST,
  };
}

export function errorMatch(error: any) {
  return {
    type: MATCH_ERROR,
    payload: { error },
  };
}

export function receiveMatch(data: any) {
  return {
    type: MATCH_SUCCESS,
    payload: data,
  };
}

// Reducer Definition
export default handleActions<MatchState>(
  {
    [MATCH_REQUEST]: handleMatchRequest,
    [MATCH_ERROR]: handleMatchError,
    [MATCH_SUCCESS]: handleMatchSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleMatchRequest(state: MatchState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleMatchError(state: MatchState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleMatchSuccess(state: MatchState, action: Action<any>) {
  return {
    ...state,
    matchList: action.payload.entities.match_products
      ? action.payload.entities.match_products
      : {},
    isRequesting: false,
    error: undefined as any,
  };
}
