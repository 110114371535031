/**
 *  A toggle button that shows two labels
 */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      userSelect: "none",
      cursor: "pointer",
      boxShadow: (props: any) =>
        props.bgWhite ? "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)" : "none",
    },
    bottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "inherit",
    },
    top: {
      position: "absolute",
      transition: "left 0.3s",
      top: "-2px",
      left: "0%",
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100% + 4px)",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: "30px",
      "& .MuiTypography-body1": {
        fontWeight: 500,
      },
    },
    topRight: {
      width: "50%",
      left: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "calc(100% + 4px)",
    },
    halfWidthButton: {
      width: "50%",
    },
    buttonSizesmall: {
      width: "200px",
      height: "30px",
      padding: "0",
      backgroundColor: (props: any) =>
        props.bgWhite ? theme.palette.common.white : theme.palette.grey[300],
      color: theme.palette.grey[900],
      borderRadius: "30px",
      "& .MuiTypography-body1": {
        fontSize: "14px",
        lineHeight: "1.6",
        textAlign: "center",
      },
    },
    buttonSizemedium: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[900],
      borderRadius: "30px",
      padding: "0 8px",
      // width: "480px",
      height: "40px",
      "& .MuiTypography-body1": {
        fontSize: "16px",
        lineHeight: "22px",
        textAlign: "center",
      },
    },
    buttonSizelarge: {},
  })
);

interface ToggleButtonProps {
  labels: string[];
  defaultLabelIndex?: number; // used for setting initial selected position
  handleChange: (label: string) => void;
  size?: "small" | "medium" | "large";
  bgWhite?: boolean; // use white instead of grey background
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  labels,
  defaultLabelIndex = 0,
  handleChange,
  size = "medium",
  bgWhite = false,
}) => {
  const [isLeft, setIsLeft] = useState<boolean>(defaultLabelIndex === 0);
  const classes = useStyles({
    bgWhite,
  });

  const toggleLeft = () => {
    const currentLeft = !isLeft;
    setIsLeft(currentLeft);
    handleChange(labels[currentLeft ? 0 : 1]);
  };

  useEffect(() => {
    setIsLeft(defaultLabelIndex === 0);
  }, [defaultLabelIndex]);

  const buttonSize = clsx({
    [classes.buttonSizesmall]: size === "small",
    [classes.buttonSizemedium]: size === "medium",
    [classes.buttonSizelarge]: size === "large",
  });

  return (
    <div className={`${classes.root} ${buttonSize}`} onClick={toggleLeft}>
      <div className={classes.bottom}>
        <Grid item className={classes.halfWidthButton}>
          <Typography variant="body1">{labels[0]}</Typography>
        </Grid>
        <Grid item className={classes.halfWidthButton}>
          <Typography variant="body1">{labels[1]}</Typography>
        </Grid>
      </div>
      <div
        className={clsx(classes.top, {
          [classes.topRight]: !isLeft,
        })}
      >
        <Typography variant="body1">{labels[isLeft ? 0 : 1]}</Typography>
      </div>
    </div>
  );
};

export default ToggleButton;
