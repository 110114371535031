import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";

// State Interface
export interface ProfileState {
  isRequesting: boolean;
  error?: any;
  language: string;
}

// Initial state
export const initialState = {
  isRequesting: false,
  error: undefined,
  language: "no",
} as ProfileState;

// Actions
const PROFILE_REQUEST = "profile/REQUEST";
const PROFILE_ERROR = "profile/ERROR";
const PROFILE_SUCCESS = "profile/SUCCESS";
const PROFILE_SET_LANGUAGE = "profile/SET_LANGUAGE";

// Action creators
export function requestOuterBody() {
  return {
    type: PROFILE_REQUEST,
  };
}

export function errorOuterBody(error: any) {
  return {
    type: PROFILE_ERROR,
    payload: { error },
  };
}

export function receiveOuterBody(data: any) {
  return {
    type: PROFILE_SUCCESS,
    payload: data,
  };
}

export function setLanguage(data: any) {
  return {
    type: PROFILE_SET_LANGUAGE,
    payload: data,
  };
}

// Reducer Definition
export default handleActions<ProfileState>(
  {
    [PROFILE_REQUEST]: handleRequest,
    [PROFILE_ERROR]: handleError,
    [PROFILE_SUCCESS]: handleSuccess,
    [PROFILE_SET_LANGUAGE]: handleSetLanguage,
  } as any,
  initialState
);

// Reducer Description
function handleRequest(state: ProfileState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleError(state: ProfileState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload.status,
  };
}

function handleSuccess(state: ProfileState, action: Action<any>) {
  return {
    ...state,
    isRequesting: false,
    error: undefined as any,
  };
}

function handleSetLanguage(state: ProfileState, action: Action<any>) {
  return {
    ...state,
    language: action.payload,
    error: undefined as any,
  };
}
