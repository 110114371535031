import React from "react";
import ReactPDF, { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import { colors } from "../config/theme";

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 3,
    marginLeft: 2,
    marginBottom: 10,
  },
  title: {
    fontFamily: "PreemSans Bold",
    fontSize: 18,
    marginVertical: 3,
    color: colors.green,
  },
  subTitle: {
    fontFamily: "PreemSans Bold",
    fontSize: 8,
    marginVertical: 3,
    color: colors.green,
  },
  merchantLogo: {
    paddingLeft: 8,
    paddingRight: 8,
    flex: 1,
    justifyContent: "center",
    height: 40,
  },
  smallLogo: {
    height: 40,
    width: 40,
    borderRadius: 2,
  },
  image: {
    height: 40,
    width: 40,
    borderRadius: 2,
  },
});

interface HeaderProps {
  title?: string;
  subtitle?: string;
  logo?: string;
  logoMerchant?: string;
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  logo,
  logoMerchant,
  style = [],
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={[styles.root, ...externalStyles]} fixed>
      <View>
        {title && <Text style={styles.title}>{title}</Text>}
        {subtitle && <Text style={styles.subTitle}>{subtitle}</Text>}
      </View>
      {logoMerchant && (
        <View style={styles.merchantLogo}>
          <Image
            src={logoMerchant}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </View>
      )}
      {logo && <Image src={logo} style={styles.smallLogo} />}
    </View>
  );
};

export default Header;
