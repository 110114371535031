import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CustomerReportShort from "./CustomerReportV2";
import CustomerReportLong from "./CustomerReportV2/CustomerReportV2T2";
import EndCustomerReportShort from "./EndCustomerReportV2";
import EndCustomerReportLong from "./EndCustomerReportV2/CustomerReportV2T2";
import ButtonRounded from "../../../components/ButtonRounded";
import Box from "@material-ui/core/Box";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(1),
    },
  })
);

interface Props {
  language: "EN" | "NO";
  version: "Full" | "Simple";
  data?: any;
  disabled?: boolean;
  type?: string | undefined;
}

const ButtonDownloadCustomerReport: React.FC<Props> = ({
  type,
  language,
  version,
  data,
  disabled = false,
}) => {
  const classes = useStyles();

  if (!data || !type) return null;

  const Button = () => (
    <Box flex={1} display="flex" className={classes.margin}>
      <ButtonRounded
        variant="contained"
        color="primary"
        disabled={disabled}
        fullWidth
      >
        {
          <FormattedMessage id="merchantView.downloadReport.modal.downloadPDF" />
        }
      </ButtonRounded>
    </Box>
  );

  if (type === "endCustomerReport")
    return (
      <PDFDownloadLink
        style={{ textDecoration: "none" }}
        document={
          version === "Full" ? (
            <EndCustomerReportLong language={language} data={data} />
          ) : (
            <EndCustomerReportShort language={language} data={data} />
          )
        }
        fileName={
          "Bærekraftrapport-" +
          data.tax_year +
          "-fra-" +
          data.merchant_name +
          "-til-" +
          data.customer_name
        }
      >
        {({ blob, url, loading, error }) => {
          if (!loading) return Button();
        }}
      </PDFDownloadLink>
    );

  if (type === "customerReport")
    return (
      <PDFDownloadLink
        style={{ textDecoration: "none" }}
        document={
          version === "Full" ? (
            <CustomerReportLong language={language} data={data} />
          ) : (
            <CustomerReportShort language={language} data={data} />
          )
        }
        fileName={
          "Bærekraftrapport-" +
          data.tax_year +
          "-fra-Preem-Norge-AS-til-" +
          data.customer_name
        }
      >
        {({ blob, url, loading, error }) => {
          if (!loading) return Button();
        }}
      </PDFDownloadLink>
    );

  return null;
};

export default React.memo(ButtonDownloadCustomerReport);
