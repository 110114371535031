import { getToken } from "./utils";
import { BASE_URL } from "./endpoints";

const getCustomerReport = async (id: number, data: any) => {
  const token = getToken();

  let valid = false;
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/customer_reports/ton_equivalence_report?customer_name=${
          data.customer_name && encodeURIComponent(data.customer_name)
        }&customer_no=${data.customer_no}&tax_year=${data.tax_year}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      var reportResult = await response.json();
      valid =
        reportResult && reportResult.success && reportResult.customer_reports;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (reportResult && reportResult.errors)
      return {
        valid: false,
        message: reportResult.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: reportResult.customer_reports,
  };
};

export default getCustomerReport;
