import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import { updateSelectedYear } from "../reducers/general";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: theme.palette.primary.main,
      minWidth: "120px",
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);

interface Props {
  fullWidth?: boolean;
  className?: any;
}

interface StateProps {
  data: any[];
  value: any;
  updateSelectedYear: any;
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  data: state.general.year.yearList,
  value: state.general.year.selectedYear,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch,
    updateSelectedYear: (year: number) => dispatch(updateSelectedYear(year)),
  };
};

const YearSelect = ({
  data,
  value,
  updateSelectedYear,
  className,
  ...rest
}: SelectProps & StateProps) => {
  const classes = useStyles();

  return (
    <Select
      {...rest}
      value={value}
      onChange={e => updateSelectedYear(e.target.value)}
      input={<OutlinedInput labelWidth={0} />}
      className={clsx(classes.select, className, {
        [classes.greyText]: !value || value === "",
      })}
    >
      {data
        ? data.map((value: any, index: number) => (
            <MenuItem key={"Yearselect" + index} value={value.year}>
              {value.year}
            </MenuItem>
          ))
        : null}
    </Select>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(YearSelect);
