import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";
import { normalize, schema } from "normalizr";
const biostorage_product = new schema.Entity("biostorage_products");
const mySchema = { biostorage_products: [biostorage_product] };

export default () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const selectedYear = state.general.year.selectedYear;
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/v1/biostorage_products?tax_year=${selectedYear}&merchant_id=${customer_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          "BIOSTORAGE_REQUEST",
          {
            type: "BIOSTORAGE_SUCCESS",
            payload: (action: any, state: any, res: any) => {
              const contentType = res.headers.get("Content-Type");
              if (contentType && ~contentType.indexOf("json")) {
                // Just making sure res.json() does not raise an error
                return res
                  .json()
                  .then((json: any) => normalize(json, mySchema));
              }
            },
          },
          "BIOSTORAGE_ERROR",
        ],
      },
    });
  };
};
