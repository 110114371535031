import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { page, colors, typography } from "../config/theme";
import InfoBox from "../componentsV2/InfoBoxV2";
import Inputfield from "../componentsV2/InputfieldV2";
import Inputfield2 from "../componentsV2/Inputfield2V2";
import Header from "../componentsV2/HeaderV2";
//import parseData from "../../../../components/texts/parseData";
import translation from "./translationV2";
import Footnote from "../componentsV2/FootnoteV2";
import parseData from "../../../../components/texts/parseData";
import { BASE_URL } from "../../../../api/endpoints";

const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 60,
  },
  container: {
    marginTop: 5,
    marginRight: 50,
  },

  para: {
    ...typography.default,
    fontSize: 8,
    marginVertical: 2,
    textAlign: "left",
    lineHeight: 1.5,
  },

  image: {
    height: 40,
    width: 50,
    borderRadius: 2,
  },
  auditImage: {
    height: 60,
    width: 55,
    borderRadius: 2,
    paddingBottom: 15,
  },
  container_columns: {
    display: "flex",
    flexDirection: "row",
  },
  container_rows: {
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 10,
    paddingBottom: 20,
    alignItems: "flex-end",
  },
  merchantLogo: {
    justifyContent: "center",
    height: 25,
  },
});

interface Props {
  language: "EN" | "NO";
  data: any;
}

const CustomerReportV2: React.FC<Props> = ({ language, data }) => {
  const t = translation[language];
  // this part for changing date to norwegian format. works if backend data format unchanged dd month yyyy
  let dateIssued = data.date_issued;
  if (language === "NO") {
    const arraydate = dateIssued.split(" ");
    if (arraydate.length === 3) {
      dateIssued = arraydate[0] + " . mars " + arraydate[2];
    }
  }

  //console.log("heypdfdatareceived" + JSON.stringify(data));
  // console.log("array check" + JSON.stringify(data.customer_sold_reports));
  return (
    <Document>
      <Page wrap size={page.size} orientation="portrait" style={styles.page}>
        {/* Header */}
        <View wrap>
          <Header
            title={t.header_title}
            subtitle={
              t.header_subtitle1 +
              (data.seller && data.seller.name) +
              t.header_subtitle2 +
              data.supplier
            }
            logo="/assets/preem-logo.png"
          />
        </View>
        {/* infobox 1 general information */}
        <View wrap={false}>
          <InfoBox
            title={t.infoBox1_title}
            style={{ backgroundColor: colors.lightYellow }}
          >
            <Text style={[styles.para, { width: "90%" }]}>
              {t.description_paragraph1_1}
              {
                <Text
                  style={[
                    styles.para,
                    { color: colors.green, fontFamily: "PreemSans Bold" },
                  ]}
                >
                  {data.customer}
                </Text>
              }
              {t.description_paragraph1_2}
              {
                <Text
                  style={[
                    styles.para,
                    { color: colors.green, fontFamily: "PreemSans Bold" },
                  ]}
                >
                  {data.seller && data.seller.name}
                </Text>
              }
              {t.description_paragraph1_3}
              {
                <Text
                  style={[
                    styles.para,
                    { color: colors.green, fontFamily: "PreemSans Bold" },
                  ]}
                >
                  {data.delivery_period}
                </Text>
              }
              {t.description_paragraph1_4}
            </Text>
            <View style={[styles.container_columns, {}]}>
              <Inputfield
                label={t.label_reportID}
                input={data.report_id.substr(0, 32)}
                inputSize={8}
              />
              <Inputfield label={t.label_customer} input={data.customer} />
            </View>
            <View style={styles.container_columns}>
              <Inputfield
                label={t.label_period}
                input={data.delivery_period}
                inputSize={8}
              />
              <Inputfield
                label={t.label_seller}
                input={data.seller && data.seller.name}
              />
            </View>
            <View style={styles.container_columns}>
              <Inputfield
                label={t.label_date}
                input={dateIssued}
                inputSize={8}
                // input={new Date().toLocaleDateString()}
              />
              <Inputfield label={t.label_supplier} input={data.supplier} />
            </View>
          </InfoBox>
        </View>
        {/* infobox 2 total vaule */}
        <View wrap={false}>
          <InfoBox
            title={t.infoBox2_title}
            style={{ backgroundColor: colors.lightGreen }}
          >
            <Text style={[styles.para, { width: "90%" }]}>
              {t.description_paragraph2}{data.delivery_period}{t.description_paragraph2a}
            </Text>
            <View style={[styles.container_columns, {}]}>
              <View style={styles.container_rows}>
                <Inputfield
                  label={t.label_fuel}
                  input={parseData(data.total_volume) + " liter"}
                  superscript="1"
                />
                <Inputfield
                  label={t.label_emission}
                  input={parseData(data.net_emission_total, 2) + " tCO2e"}
                />
              </View>
              <Inputfield2
                label={t.label_reduction}
                input={
                  parseData(data.net_emission_reduction_total, 2) + " tCO2e"
                }
                input2={data.net_emission_reduction_percentage + " %"}
              />
            </View>
            <Footnote
              superscript="1"
              text={
                t.footnote1 + parseData(data.net_emission_fossil, 2) + " tCO2e"
              }
            />
          </InfoBox>
        </View>
        {/* infobox 3 product values */}
        <View wrap={true}>
          <InfoBox
            title={t.infoBox3_title}
            style={{ backgroundColor: colors.lightGrey }}
          >
            {/* infobox 3 loop an array */}
            {data.customer_sold_reports &&
            data.customer_sold_reports.map((item: any, index: number) => {
              let dobuleCount = item.double_count;
              if (language !== "NO" && item.double_count === "Ja") {
                dobuleCount = "Yes";
              } else if (language !== "NO" && item.double_count === "Nei") {
                dobuleCount = "No";
              }
              return (
                <View
                  key={item.volume + index}
                  style={{
                    borderBottom:
                      index != data.customer_sold_reports.length - 1 ? 2 : 0,
                    borderBottomColor: colors.green,
                    paddingBottom: 10,
                    marginBottom: 10,
                  }}
                  wrap={false}
                >
                  <Text
                    style={{
                      ...typography.default,
                      color: colors.green,
                      fontSize: 8,
                      fontFamily: "PreemSans Bold",
                    }}
                  >
                    {item.name}
                  </Text>
                  <View style={[styles.container_columns, {}]}>
                    <View style={styles.container_rows}>
                      <Inputfield
                        label={t.label_volumn}
                        input={parseData(item.volume) + " liter"}
                        superscript={(index + 2).toString()}
                      />
                      <Inputfield
                        label={t.label_emission}
                        input={parseData(item.emission_total, 2) + " tCO2e"}
                      />
                    </View>
                    <Inputfield2
                      label={t.label_reduction}
                      input={
                        parseData(item.emission_reduction_total, 2) + " tCO2e"
                      }
                      input2={item.emission_reduction_percentage + " %"}
                    />
                  </View>
                  <View style={[styles.container_columns, {}]}>
                    <Footnote
                      superscript={(index + 2).toString()}
                      text={
                        t.footnote2 +
                        parseData(item.emission_fossil, 2) +
                        " tCO2e"
                      }
                    />
                    {item.double_count && (
                      <Inputfield
                        label={t.infoBlock_adv}
                        input={dobuleCount}
                      />
                    )}
                  </View>
                </View>
              ); })}
          </InfoBox>
        </View>
        {/* infobox 4 auditor statement */}
        <View wrap={false}>
          <InfoBox
            title={t.infoBox4_title}
            style={{ backgroundColor: colors.lightGrey }}
          >
            <View style={[styles.container_columns, { marginBottom: 0 }]}>
              {/* text part of the infobox 4 on the left */}
              <View style={[styles.container, { width: "76%" }]}>
                <Text style={styles.para}> {t.description_paragraph3}{data.delivery_period}{t.description_paragraph31}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Text style={styles.para}> {t.description_paragraph4}</Text>
                  <Text
                    style={[
                      styles.para,
                      { color: colors.green, fontFamily: "PreemSans Bold" },
                    ]}
                  >
                    {t.description_paragraph5}
                  </Text>
                </View>
              </View>
              {/* icon of infobox 4 on the right */}
              <View>
                <Image src="/assets/audit.png" style={styles.auditImage} />
              </View>
            </View>
          </InfoBox>
        </View>
        {/* infobox 5 contact information */}
        <View wrap={false}>
          <InfoBox
            title={t.infoBox5_title}
            style={{ backgroundColor: colors.lightGrey }}
          >
            {/* text part of the infobox 5 on the left */}
            <View style={styles.container_columns}>
              <View style={[styles.container, { width: "35%" }]}>
                <View style={styles.container_columns}>
                  <Text style={[styles.para, { fontFamily: "PreemSans Bold" }]}>
                    {t.contact_company}
                  </Text>
                  <Text style={styles.para}> {t.contact_company2} </Text>
                </View>
                <Text style={[styles.para, { marginBottom: 0 }]}>
                  {t.contact_address}
                </Text>
              </View>
              <View style={[styles.container, { width: "29%" }]}>
                <Text
                  style={[
                    styles.para,
                    {
                      color: colors.green,
                      fontFamily: "PreemSans Bold",
                    },
                  ]}
                >
                  {t.contact_email}
                </Text>
                <Text style={[styles.para, { marginBottom: 0 }]}>
                  {t.contact_phone}
                </Text>
              </View>
              {/* icon of infobox 5 on the right */}
              <View>
                <Image src="/assets/contact.png" style={styles.image} />
              </View>
            </View>
          </InfoBox>
        </View>

        <View style={styles.footer} fixed>
          {data.seller.logo.url && (
            <View style={styles.merchantLogo}>
              <Image
                src={BASE_URL + data.seller.logo.url}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default CustomerReportV2;
