import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";
import { isAuthenticated } from "../../common/utils";

// State Interface
export interface AuthenticationState {
  isAuthenticated: boolean;
  isRequesting: boolean;
  authToken: string | null;
  userInfo: any;
  error?: any;
}

// Initial state
export const initialState = {
  isAuthenticated: isAuthenticated(),
  isRequesting: false,
  authToken: null,
  userInfo: { merchant_name: "" },
  error: undefined,
} as AuthenticationState;

// Actions
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_ERROR = "LOGIN_ERROR";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Action creators
export function requestLogin() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function errorLogin(error: any) {
  return {
    type: LOGIN_ERROR,
    payload: { error },
  };
}

export function receiveLogin(data: any) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
}

export function successLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

// Reducer Definition
export default handleActions<AuthenticationState>(
  {
    [LOGIN_REQUEST]: handleLoginRequest,
    [LOGIN_ERROR]: handleLoginError,
    [LOGIN_SUCCESS]: handleLoginSuccess,
    [LOGOUT_SUCCESS]: handleLogoutSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleLoginRequest(state: AuthenticationState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleLoginError(
  state: AuthenticationState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleLoginSuccess(state: AuthenticationState, action: Action<any>) {
  return {
    ...state,
    isAuthenticated: true,
    isRequesting: false,
    authToken: action.payload.auth_token,
    userInfo: {
      role: action.payload.role,
      user_id: action.payload.user_id,
      ownership_id: action.payload.ownership_id,
      merchant_name: action.payload.merchant_name,
    },
    error: undefined as any,
  };
}

function handleLogoutSuccess(state: AuthenticationState, action: Action<any>) {
  return {
    ...state,
    isAuthenticated: false,
    isRequesting: false,
    authToken: null,
    error: undefined as any,
  };
}
