/**
 *  Login Form
 */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, Field } from "react-final-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import TextField from "../../components/TextFieldSpread";
import { TextField } from "final-form-material-ui";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import makeValidate from "../../components/form/makeValidate";

const useStyles = makeStyles((theme: Theme) => ({
  loginTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(5.5),
  },
  loginInputContainer: {
    marginBottom: theme.spacing(2.5),
  },
  loginButton: {
    marginTop: theme.spacing(3.7),
    height: "40px",
    fontSize: "12px",
  },
  forgotButton: {
    textTransform: "none",
    paddingLeft: 0,
    fontSize: theme.typography.body1.fontSize,
  },
}));

interface LoginFormProps {
  goToForgotPassword: () => void;
  login: any;
}

const LoginForm: React.FC<LoginFormProps> = ({ goToForgotPassword, login }) => {
  const classes = useStyles();

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const onSubmit = async (values: any) => {
    const loginResponse = await login(values.username, values.password);
    if (!loginResponse.valid) {
      return {
        password: loginResponse.message,
      };
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={values => makeValidate(values, validationSchema)}
        render={({ handleSubmit, submitting, active, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" className={classes.loginTitle}>
              <FormattedMessage id={"loginView.logInTitle"} />
            </Typography>
            <FormattedMessage id={"loginView.username"}>
              {username => (
                <Field
                  className={classes.loginInputContainer}
                  component={TextField}
                  name="username"
                  placeholder={username}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutlinedIcon
                          color={active === "username" ? "action" : "disabled"}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id={"loginView.password"}>
              {password => (
                <Field
                  className={classes.loginInputContainer}
                  type="password"
                  component={TextField}
                  name="password"
                  placeholder={password}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon
                          color={active === "password" ? "action" : "disabled"}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </FormattedMessage>
            <Box>
              <Button
                color="inherit"
                className={classes.forgotButton}
                onClick={goToForgotPassword}
              >
                <FormattedMessage id={"loginView.forgotPassword"} />
              </Button>
            </Box>
            <Button
              className={classes.loginButton}
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={submitting || hasValidationErrors}
            >
              <FormattedMessage id={"loginView.logInButton"} />
            </Button>
          </form>
        )}
      />
    </>
  );
};

export default LoginForm;
