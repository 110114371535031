/**
 * Customers table
 */

import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import goToCustomer from "../../actions/goToCustomer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
    },
    titleBar: {
      paddingBottom: theme.spacing(4),
    },
    search: {
      width: "600px",
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
        fontSize: "14px",
      },
    },
    table: {
      cursor: "pointer",
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    chevronIcon: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: "50%",
      marginBottom: -4,
    },
    top5Label: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: "25%",
      padding: theme.spacing(0.5),
      width: "100px",
    },
    customerLink: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: "16px",
    },
    bold: { fontWeight: "bold" },
    noData: {
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  })
);

const mapStateToProps = (state: State) => ({
  customerList: state.general.customers.customerList,
  language: state.profile.language,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  goToCustomer: (
    customer_idx: number,
    customer_no: string,
    customer_name: string
  ) => dispatch(goToCustomer(customer_idx, customer_no, customer_name)),
});

interface OwnProps {
  isValid: boolean;
}

interface Props {
  customerList: any;
  goToCustomer: any;
  language: string;
}

const CustomersTable = ({
  customerList,
  goToCustomer,
  language,
  isValid,
}: Props & OwnProps) => {
  const [searchValue, SetSearchValue] = useState<string>("");

  const classes = useStyles();

  const newCustomerList = customerList.map((customer: any, idx: number) => {
    return { idx: idx, ...customer };
  });

  const filteredCustomerList =
    searchValue.trim().length > 0
      ? newCustomerList.filter(
          (c: any) =>
            String(c.customer_name)
              .toLowerCase()
              .indexOf(searchValue.trim().toLowerCase()) > -1
        )
      : [...newCustomerList];

  return (
    <div>
      <Card>
        <CardContent className={classes.root}>
          {/* Top Search Bar */}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid item>
              <Typography variant="h5">
                <FormattedMessage id="merchantView.customers" />
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <TextField
                  placeholder={language === "no" ? "Søk" : "Search"}
                  className={classes.search}
                  onChange={(e) => SetSearchValue(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {!isValid ? (
            <div className={classes.noData}>
              <Typography variant="body2">
                <FormattedMessage id="merchantView.matchNotCompleted" />
              </Typography>
            </div>
          ) : !(
              Array.isArray(filteredCustomerList) &&
              filteredCustomerList.length > 0
            ) ? (
            <div className={classes.noData}>
              <Typography variant="body2">
                <FormattedMessage id="merchantView.noCustomers" />
              </Typography>
            </div>
          ) : (
            <Table className={classes.table}>
              <TableBody>
                {filteredCustomerList.map((customer, idx) => (
                  <TableRow
                    key={idx}
                    onClick={() =>
                      goToCustomer(
                        customer.idx,
                        customer.customer_no,
                        customer.customer_name
                      )
                    }
                  >
                    <TableCell>
                      <Typography className={classes.customerLink}>
                        {customer.customer_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container justify="flex-end" alignItems="center">
                        {idx < 5 && (
                          <Grid item>
                            <Chip
                              style={{
                                backgroundColor: "rgba(1,130,59, 1)",
                                color: "white",
                                fontWeight: "bold",
                                height: "24px",
                                width: "72px",
                                marginRight: "32px",
                              }}
                              label={
                                <FormattedMessage id="merchantView.top5" />
                              }
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <Icon className={classes.chevronIcon}>
                            {"navigate_next"}
                          </Icon>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTable);
