/**
 *  Welcome message & about section
 */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    position: "relative",
    padding: theme.spacing(10),
  },
  logo: {},
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    marginBottom: theme.spacing(8),
    maxWidth: "380px",
  },
  welcomeListItem: {
    display: "flex",
    marginBottom: theme.spacing(2.5),
  },
  titleText: {
    lineHeight: "72px",
  },
  titleOrange: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    lineHeight: "72px",
  },
}));

const Welcome: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Typography variant="h4">
          <FormattedMessage id={"loginView.logo"} />
        </Typography>
      </div>

      <div className={classes.content}>
        <div className={classes.title}>
          <Typography variant="h1" className={classes.titleText}>
            <FormattedMessage id={"loginView.preem"} />
          </Typography>
          <Typography variant="h1" className={classes.titleOrange}>
            <FormattedMessage id={"loginView.title"} />
          </Typography>
        </div>
        <div className={classes.welcomeListItem}>
          <CheckCircleOutlineIcon />
          <Typography variant="body1" display="inline">
            &nbsp; <FormattedMessage id={"loginView.subtitle1"} />
          </Typography>
        </div>
        <div className={classes.welcomeListItem}>
          <CheckCircleOutlineIcon />
          <Typography variant="body1" display="inline">
            &nbsp; <FormattedMessage id={"loginView.subtitle2"} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
