import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../components/ButtonRounded";
import ButtonArrowBack from "../../../components/ButtonArrowBack";
import ExtendableList from "../../../components/ExtendableList";
import { Card, CardContent, Typography, Fab } from "@material-ui/core";
import CustomerRequirementProperties from "./CustomerRequirementProperties";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: theme.spacing(5),
    },
    card: {
      padding: theme.spacing(2),
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    headerCard: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(4),
    },
  })
);

interface CustomerViewProps {
  selectedCustomer: number;
  customer: any;
  loading: boolean;
  customerReport: any[];
  openModalReport: any;
}

const CustomerRequirementView: React.FC<CustomerViewProps> = ({
  selectedCustomer,
  customer,
  loading,
  customerReport,
  openModalReport,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="stretch" spacing={5}>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <ButtonArrowBack />
        </Grid>
        <Grid item>
          <ButtonRounded
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => openModalReport(customer)}
          >
            <FormattedMessage id="customerView.list.buttonDownload" />
          </ButtonRounded>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.headerCard}>
                <Box>
                  <Typography variant="h5">{customer.customer_name}</Typography>
                  <Typography variant="subtitle1">
                    {"Org nr. " + customer.customer_no}
                  </Typography>
                </Box>
                {selectedCustomer < 5 && (
                  <Chip
                    style={{
                      backgroundColor: "rgba(1,130,59, 1)",
                      color: "white",
                      fontWeight: "bold",
                      height: "24px",
                      width: "72px",
                      marginLeft: "32px",
                    }}
                    label={<FormattedMessage id="merchantView.top5" />}
                  />
                )}
                <Grid item xs="auto" sm="auto"></Grid>
              </Box>
              {loading ? (
                <Box
                  height="200px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <ExtendableList
                  expandProperties={CustomerRequirementProperties}
                  list={customerReport}
                  action={{
                    deleteEntry: null,
                    editEntry: null,
                  }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerRequirementView;
