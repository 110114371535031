import { push } from "connected-react-router";
import { selectCustomer } from "../reducers/general/customers";
import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";

export default (
  customer_idx?: number,
  customer_no?: string,
  customer_name?: string
) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    const selectedYear = state.general.year.selectedYear;

    if (
      customer_no === undefined ||
      customer_idx === undefined ||
      customer_name === undefined
    ) {
      customer_idx = state.general.customers.selectCustomer;
      customer_no =
        state.general.customers.customerList[customer_idx ? customer_idx : 0]
          .customer_no;
      customer_name =
        state.general.customers.customerList[customer_idx ? customer_idx : 0]
          .customer_name;
    } else {
      dispatch(selectCustomer(customer_idx));
      dispatch(push("/customer"));
    }

    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/v1/customer_reports?customer_no=${customer_no}&tax_year=${selectedYear}&customer_name=${
          customer_name && encodeURIComponent(customer_name)
        }&merchant_id=${customer_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          "CUSTOMER_REPORT_REQUEST",
          "CUSTOMER_REPORT_SUCCESS",
          "CUSTOMER_REPORT_ERROR",
        ],
      },
    });
  };
};
