import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";
import getBioProducts from "./getBioProducts";
import getMatchProducts from "./getMatchProducts";
import getCustomers from "./getCustomers";
import getFiles from "./getFiles";
import { uploadError } from "../reducers/files";

const getFileStatus = (file_id: any) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/v1/sales_groups/${file_id}/status?merchant_id=${customer_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: [
          "FILE_STATUS_REQUEST",
          {
            type: "FILE_STATUS_SUCCESS",
            payload: (action: any, state: any, res: any) => {
              const contentType = res.headers.get("Content-Type");
              if (contentType && ~contentType.indexOf("json")) {
                // Just making sure res.json() does not raise an error
                return res.json().then((json: any) => {
                  if (json.status === "incomplete") {
                    setTimeout(() => dispatch(getFileStatus(file_id)), 3000);
                    return;
                  }
                  if (json.status === "completed") {
                    dispatch(getFiles());
                    dispatch(getBioProducts());
                    dispatch(getMatchProducts());
                    dispatch(getCustomers());
                    return;
                  } else
                    dispatch(uploadError("The file couldn't be processed"));
                });
              }
            },
          },
          "FILE_STATUS_ERROR",
        ],
      },
    });
  };
};

export default getFileStatus;
