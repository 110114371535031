import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import ReportModal from "../views/MainView/ReportModal";

interface Props {
  show: boolean;
  handleHide: any;
  selectedYear?: number;
  merchant?: any;
  customer?: any;
  messageId?: string;
  loading?: boolean;
  error?: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      selectedYear: state.general.year.selectedYear,
      merchant: state.authentication.userInfo,
      loading: state.files.isRequesting,
      error: state.files.error,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(ReportModal);
