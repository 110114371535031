import { RSAA } from "redux-api-middleware";

const apiAuthMiddleware = (store: any) => (next: any) => (action: any) => {
  // Pass to next middleware if not a redux-api-middleware action
  const state = store.getState();

  if (!action[RSAA] || !state.authentication.authToken) {
    return next(action);
  }

  const newAction = {
    ...action,
    [RSAA]: {
      ...action[RSAA],
      headers: {
        ...action[RSAA].headers,
        Authorization: state.authentication.authToken,
      },
    },
  };

  return next(newAction);
};

export default apiAuthMiddleware;
