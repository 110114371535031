import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import parseData from "./texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        padding: theme.spacing(0.5),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
      },
    },
    iconRed: {
      marginBottom: -4,
      marginRight: 2,
      color: theme.palette.error.main,
    },
    iconGreen: {
      marginBottom: -4,
      marginRight: 2,
      color: "rgba(1,130,59, 1)",
    },
    iconGrey: {
      marginBottom: -4,
      marginRight: 2,
      color: "rgba(194, 209, 217, 1)",
    },
    rightAlign: { textAlign: "right" },
  })
);

interface FilesTableProps {
  headCells: any;
  list: any[];
  matchIsOk?: boolean;
  hideIndications?: boolean;
}

const SmartTable: React.FC<FilesTableProps> = ({
  headCells,
  list,
  matchIsOk,
  hideIndications,
}) => {
  const classes = useStyles();

  const getIcon = (trend: string) => {
    let iconName = "";
    let iconClass = classes.iconGreen;
    switch (trend) {
      case "ok":
        iconName = "done";
        break;
      case "notOk":
        iconName = "close";
        iconClass = classes.iconRed;
        break;
      case "allowed":
          iconName = "done";
          iconClass = classes.iconGrey;
          break;
      case "down":
        iconName = "arrow_downward";
        break;
      case "up":
        iconName = "arrow_upward";
        break;
    }
    return (
      <Icon className={iconClass} fontSize="small">
        {iconName}
      </Icon>
    );
  };

  const getRowColor = (row: any) => {
    if (hideIndications || row.trend === "allowed") return;
    if (row.trend === "notOk") return "#ffdad1";
    if (matchIsOk && !(row.volume_bought === row.volume_sold)) return "#ffeed7";
    if (matchIsOk && (row.trend === "up" || row.trend === "down"))
      return "#D1E5D9";
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          {headCells.map((column: any, idx: number) => (
            <TableCell
              key={`headerCell-${column.id}-${idx}`}
              align={column.rightAlign ? "right" : "left"}
            >
              {column.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(list) &&
          list.map((row, idx) => (
            <TableRow
              key={`${row.id}-${idx}`}
              style={{ backgroundColor: getRowColor(row) }}
            >
              {headCells.map((column: any, cellIndex: number) => (
                <TableCell
                  key={`row-${row.id}-${idx}-cell-${cellIndex}`}
                  align={column.rightAlign ? "right" : "left"}
                >
                  {row[column.id] ? parseData(row[column.id]) : "-"}
                </TableCell>
              ))}
              <TableCell align="right">
                {!hideIndications && getIcon(row.trend)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default SmartTable;
