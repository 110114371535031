import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import { routerMiddleware } from "connected-react-router";
import apiAuthMiddleware from "./apiAuthMiddleware";
import { createBrowserHistory } from "history";
import createRootReducer from "./reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const persistConfig = {
  key: "root",
  storage,
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, createRootReducer);

const sagaMiddleware = createSagaMiddleware();
export const loggerMiddleware = createLogger();

export const middleware =
  process.env.NODE_ENV === "development"
    ? applyMiddleware(
        routerMiddleware(history),
        apiAuthMiddleware,
        apiMiddleware,
        sagaMiddleware,
        promiseMiddleware,
        thunkMiddleware,
        loggerMiddleware
      )
    : applyMiddleware(
        routerMiddleware(history),
        apiAuthMiddleware,
        apiMiddleware,
        sagaMiddleware,
        promiseMiddleware,
        thunkMiddleware
      );

export const store = createStore(
  persistedReducer,
  compose(
    middleware,
    process.env.NODE_ENV === "development" &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export const persistor = persistStore(store);
