/**
 *  A modal template component for building consistent modals / dialogs
 */

import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { connectModal } from "redux-modal";
import { FormattedMessage } from "react-intl";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "430px",
      minHeight: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(6),
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1.25),
      left: theme.spacing(1.25),
      color: theme.palette.grey[900],
    },
    message: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
    circularProgress: { width: "48px", height: "48px" },
  })
);

interface Props {
  show: boolean;
  handleHide: any;
  messageId?: string;
  loading?: boolean;
  error?: any;
}

const FileModal: React.FC<Props> = ({
  show,
  handleHide,
  messageId,
  loading,
  error,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={show} onClose={handleHide}>
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={handleHide}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {messageId && <FormattedMessage id={messageId.concat(".title")} />}
        </Typography>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress className={classes.circularProgress} />
          ) : (
            <Typography variant="body1" className={classes.message}>
              {messageId &&
                (error ? (
                  <FormattedMessage id={messageId.concat(".error")} />
                ) : (
                  <FormattedMessage id={messageId.concat(".success")} />
                ))}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default connectModal({ name: "file", destroyOnHide: true })(FileModal);
