/**
 *  Side navigation panel
 */

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import ToggleButton from "../../components/ToggleButton";
import MerchantSelect from "../../components/MerchantSelect";
import Box from "@material-ui/core/Box";
import { isAuditor } from "../../api/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: "100%",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    label: {
      marginRight: theme.spacing(1),
    },
  })
);

interface Props {
  logout: any;
  language: string;
  setLanguage: any;
}

const Header: React.FC<Props> = ({ logout, language, setLanguage }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography variant="h4">
        <FormattedMessage id="header.fp" />
      </Typography>
      {process.env.NODE_ENV === "development" && (
        <ToggleButton
          labels={["en", "no"]}
          defaultLabelIndex={language === "en" ? 0 : 1}
          handleChange={setLanguage}
          size="small"
        />
      )}
      {isAuditor() && (
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle2" className={classes.label}>
              <FormattedMessage id="header.chooseReseller" />
            </Typography>
          </Box>
          <Box flex={1}>
            <MerchantSelect fullWidth />
          </Box>
        </Box>
      )}

      <Button onClick={logout}>
        <FormattedMessage id="header.signOutButton" />
      </Button>
    </Card>
  );
};

export default Header;
