import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import { selectMerchant } from "../reducers/general/merchants";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: theme.palette.primary.main,
      minWidth: "120px",
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);

interface Props {
  fullWidth?: boolean;
  className?: any;
}

interface StateProps {
  data: any[];
  value: any;
  selectMerchant: any;
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  data: state.general.merchants.merchantList,
  value: state.general.merchants.selectedMerchant,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch,
    selectMerchant: (merchant: any) => dispatch(selectMerchant(merchant)),
  };
};

const MerchantSelect = ({
  data,
  value,
  selectMerchant,
  className,
  ...rest
}: SelectProps & StateProps) => {
  const classes = useStyles();

  if (!value) value = { id: "", name: "" };

  return (
    <Select
      {...rest}
      value={value.customer_id}
      onChange={(e) =>
        selectMerchant(
          data.filter((val) => val.customer_id === e.target.value)[0]
        )
      }
      input={<OutlinedInput labelWidth={0} />}
      className={clsx(classes.select, className, {
        [classes.greyText]: !value || value === "",
      })}
    >
      {data
        ? data.map((value: any, index: number) => (
            <MenuItem key={"Merchantselect" + index} value={value.customer_id}>
              {value.name}
            </MenuItem>
          ))
        : null}
    </Select>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSelect);
