import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";

export default () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/v1/sales_groups?merchant_id=${customer_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: ["FILE_LIST_REQUEST", "FILE_LIST_SUCCESS", "FILE_LIST_ERROR"],
      },
    });
  };
};
