import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import { show } from "redux-modal";
import CustomerView from "../views/MainView/CustomerView";

interface Props {
  selectedCustomer: number;
  customer: any;
  loading: boolean;
  customerReport: any[];
  openModalReport: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      loading: state.general.customers.isRequestingReport,
      selectedCustomer: state.general.customers.selectedCustomer,
      customerReport: state.general.customers.report,
      customer:
        state.general.customers.customerList[
          state.general.customers.selectedCustomer
        ],
    }),
    (dispatch: any) => ({
      dispatch,
      openModalReport: (customer: any) =>
        dispatch(
          show("customerReport", {
            messageId: "merchantView.downloadReport.modal",
            type: "endCustomerReport",
            customer: customer,
          })
        ),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(CustomerView);
