import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";

// State Interface
export interface CustomersState {
  isRequesting: boolean;
  isRequestingReport: boolean;
  customerList: any[];
  selectedCustomer: number;
  report: any[];
  error?: any;
}

// Initial state
export const initialState = {
  customerList: [],
  selectedCustomer: 0,
  report: [],
  isRequesting: false,
  isRequestingReport: false,
  error: undefined,
} as CustomersState;

// Actions
const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
const CUSTOMER_LIST_ERROR = "CUSTOMER_LIST_ERROR";
const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";

const CUSTOMER_REPORT_REQUEST = "CUSTOMER_REPORT_REQUEST";
const CUSTOMER_REPORT_ERROR = "CUSTOMER_REPORT_ERROR";
const CUSTOMER_REPORT_SUCCESS = "CUSTOMER_REPORT_SUCCESS";

const SELECT_CUSTOMER = "SELECT_CUSTOMER";

// Action creators
export function requestCustomers() {
  return {
    type: CUSTOMER_LIST_REQUEST,
  };
}

export function errorCustomers(error: any) {
  return {
    type: CUSTOMER_LIST_ERROR,
    payload: { error },
  };
}

export function receiveCustomers(data: any) {
  return {
    type: CUSTOMER_LIST_SUCCESS,
    payload: data,
  };
}

export function requestReport() {
  return {
    type: CUSTOMER_REPORT_REQUEST,
  };
}

export function errorReport(error: any) {
  return {
    type: CUSTOMER_REPORT_ERROR,
    payload: { error },
  };
}

export function receiveReport(data: any) {
  return {
    type: CUSTOMER_REPORT_SUCCESS,
    payload: data,
  };
}

export function selectCustomer(customer_idx: number) {
  return {
    type: SELECT_CUSTOMER,
    payload: customer_idx,
  };
}

// Reducer Definition
export default handleActions<CustomersState>(
  {
    [CUSTOMER_LIST_REQUEST]: handleCustomerListRequest,
    [CUSTOMER_LIST_ERROR]: handleCustomerListError,
    [CUSTOMER_LIST_SUCCESS]: handleCustomerListSuccess,
    [CUSTOMER_REPORT_REQUEST]: handleCustomerReportRequest,
    [CUSTOMER_REPORT_ERROR]: handleCustomerReportError,
    [CUSTOMER_REPORT_SUCCESS]: handleCustomerReportSuccess,
    [SELECT_CUSTOMER]: handleSelectCustomer,
  } as any,
  initialState
);

// Reducer Description
function handleCustomerListRequest(state: CustomersState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleCustomerListError(
  state: CustomersState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleCustomerListSuccess(state: CustomersState, action: Action<any>) {
  return {
    ...state,
    customerList: Array.isArray(action.payload.customers)
      ? action.payload.customers
      : [],
    isRequesting: false,
    error: undefined as any,
  };
}

function handleCustomerReportRequest(state: CustomersState) {
  return {
    ...state,
    report: [],
    isRequestingReport: true,
    error: undefined as any,
  };
}

function handleCustomerReportError(
  state: CustomersState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequestingReport: false,
    error: action.payload,
  };
}

function handleCustomerReportSuccess(
  state: CustomersState,
  action: Action<any>
) {
  return {
    ...state,
    report: action.payload.customer_sold_reports,
    isRequestingReport: false,
    error: undefined as any,
  };
}

function handleSelectCustomer(state: CustomersState, action: Action<any>) {
  return {
    ...state,
    selectedCustomer: action.payload,
    isRequesting: false,
    error: undefined as any,
  };
}
