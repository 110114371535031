import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import DeleteFileModal from "../views/MainView/DeleteFileModal";
import deleteFile from "../actions/deleteFile";

interface Props {
  show: boolean;
  handleHide: any;
  fileName?: string;
  messageId?: string;
  loading?: boolean;
  error?: any;
  deleteFile?: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => {
      const fileList = Array.isArray(state.files.fileList)
        ? state.files.fileList
        : [];
      const selectedYear = state.general.year.selectedYear;
      let file = fileList.filter(
        (file: any) => file.tax_year === selectedYear
      )[0];

      return {
        loading: state.files.isRequesting,
        error: state.files.error,
        fileName: file ? file.file_name : null,
      };
    },
    (dispatch: any) => ({
      dispatch,
      deleteFile: () => dispatch(deleteFile()),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(DeleteFileModal);
