import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  IconButton,
  Fab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import parseData from "./texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionPanel: {
      padding: 0,
      backgroundColor: "#fdefd9",
      width: "100%",
      overflowX: "scroll",
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        //backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "small",
        fontWeight: 500,
        whiteSpace: "nowrap",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      "& .MuiTableCell-body": {
        color: "inherit",
        fontSize: "small",
        fontWeight: 400,
        paddingBottom: theme.spacing(2),
      },
    },
    iconDelete: {
      // color: theme.palette.error.main,
      marginLeft: "20px",
    },
    iconEdit: {
      marginLeft: "30px",
    },

    heading: {
      fontSize: "inherit",
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginLeft: "2%",
    },
    panelBody: {
      boxShadow: "none",
      "&:before": {
        height: "0",
      },
      "&:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "&.Mui-expanded": {
        margin: 0,
        "& .MuiFab-sizeSmall": {
          transform: "rotate(180deg)",
        },
      },
    },
    toggleIcon: {
      margin: theme.spacing(2),
      transform: "rotate(0deg)",
      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    toggleAggregateIcon: {
      marginLeft: theme.spacing(2),
    },
    panelTitle: {
      padding: theme.spacing(0, 1),
    },
  })
);

export interface IExtendList {
  [key: string]: boolean;
}

interface IExtendableListProps {
  expandProperties: any[];
  list: any[];
  action: { deleteEntry?: any; editEntry?: any };
}

function ExtendableList(props: IExtendableListProps) {
  const { expandProperties, list, action } = props;
  const classes = useStyles();
  const [visibleAggregates, setVisibleAggregates] = useState<{
    [id: string]: any;
  }>([]);

  const getExpansionPanelSummary = (requirement: any) => {
    return (
      <ExpansionPanelSummary className={classes.panelTitle}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Fab color="primary" size="small" className={classes.toggleIcon}>
            <ExpandMoreIcon fontSize="small" />
          </Fab>
          <Typography className={classes.heading}>
            {requirement.product_name}
          </Typography>
        </Grid>

        <Grid container justify="flex-end" alignItems="center">
          <Grid item>
            {action.editEntry && (
              <IconButton
                className={classes.iconEdit}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  action.editEntry(requirement);
                }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {action.deleteEntry && (
              <IconButton
                className={classes.iconDelete}
                color="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                  action.deleteEntry(requirement);
                }}
              >
                <DeleteOutlinedIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
    );
  };

  const getExpansionPanelDetails = (requirement: any) => {
    return (
      <ExpansionPanelDetails className={classes.expansionPanel}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {expandProperties.map((column: any) => {
                return (
                  <React.Fragment>
                    <TableCell key={column.id + "columnHead"}>
                      {column.name}
                      {column.aggregated_data && (
                        <Fab
                          color="primary"
                          size="small"
                          className={classes.toggleAggregateIcon}
                          onClick={() => {
                            if (visibleAggregates[column.id]) {
                              const {
                                [column.id]: removedAggregate,
                                ...newAggregates
                              } = visibleAggregates;
                              setVisibleAggregates(newAggregates);
                            } else
                              setVisibleAggregates({
                                ...visibleAggregates,
                                [column.id]: [],
                              });
                            console.log(visibleAggregates);
                          }}
                        >
                          {visibleAggregates[column.id] ? (
                            <RemoveIcon fontSize="small" />
                          ) : (
                            <AddIcon fontSize="small" />
                          )}
                        </Fab>
                      )}
                    </TableCell>

                    {visibleAggregates[column.id] &&
                      column.aggregated_data &&
                      column.aggregated_data.map((agg_column: any) => {
                        return (
                          <TableCell
                            key={agg_column.id + "columnHead"}
                            style={{ backgroundColor: "#ffac12" }}
                          >
                            {agg_column.name}
                          </TableCell>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {expandProperties.map((column: any) => {
                return (
                  <React.Fragment>
                    <TableCell key={column.id + "columnBody"}>
                      {requirement[column.id] ? (
                        parseData(requirement[column.id], 2)
                      ) : (
                        <Typography
                          variant="body2"
                          style={{ fontStyle: "italic" }}
                        >
                          Not specified
                        </Typography>
                      )}
                    </TableCell>
                    {visibleAggregates[column.id] &&
                      column.aggregated_data &&
                      column.aggregated_data.map((agg_column: any) => {
                        return (
                          <TableCell
                            key={agg_column.id + "columnBody"}
                            style={{
                              backgroundColor: "#ffac12",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {requirement[agg_column.id] ? (
                              parseData(requirement[agg_column.id], 2)
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ fontStyle: "italic" }}
                              >
                                Not specified
                              </Typography>
                            )}
                          </TableCell>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    );
  };

  return (
    <Grid>
      {list.map((requirement: any) => {
        return (
          <ExpansionPanel
            key={requirement.id + "ExpansionPanel"}
            className={classes.panelBody}
          >
            {getExpansionPanelSummary(requirement)}
            {getExpansionPanelDetails(requirement)}
          </ExpansionPanel>
        );
      })}
    </Grid>
  );
}

export default ExtendableList;
