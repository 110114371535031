import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import LoginView from "../views/LoginView";
import { login } from "../actions/authentication";

interface Props {
  history: any; // React router
  login: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({}),
    (dispatch: any) => ({
      dispatch,
      login: (username: string, password: string) =>
        dispatch(login(username, password)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(LoginView);
