/**
 *  A modal template component for building consistent modals / dialogs
 */

import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { connectModal } from "redux-modal";
import { FormattedMessage } from "react-intl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../components/ButtonRounded";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "430px",
      minHeight: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(6),
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1.25),
      left: theme.spacing(1.25),
      color: theme.palette.grey[900],
    },
    fileName: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      fontWeight: "bold",
    },
    errorMessage: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
    },
    icon: { color: theme.palette.grey[300] },
    circularProgress: { width: "48px", height: "48px" },
  })
);

interface Props {
  show: boolean;
  handleHide: any;
  fileName?: string;
  messageId?: string;
  loading?: boolean;
  error?: any;
  deleteFile?: any;
}

const DeleteFileModal: React.FC<Props> = ({
  show,
  handleHide,
  fileName,
  messageId,
  loading,
  error,
  deleteFile,
}) => {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);

  if (isDirty && !loading && !error) handleHide();

  return (
    <Dialog open={show} onClose={handleHide}>
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={handleHide}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {messageId && <FormattedMessage id={messageId.concat(".title")} />}
        </Typography>
        <Box
          height="150px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress className={classes.circularProgress} />
          ) : messageId && isDirty ? (
            <Typography variant="body1" className={classes.errorMessage}>
              <FormattedMessage id={messageId.concat(".error")} />
            </Typography>
          ) : (
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <DescriptionOutlinedIcon
                fontSize="large"
                className={classes.icon}
              />
              <Typography variant="body2" className={classes.fileName}>
                {fileName}
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={handleHide}
              color="secondary"
            >
              Cancel
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteFile();
                setIsDirty(true);
              }}
            >
              Delete
            </ButtonRounded>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default connectModal({ name: "deleteFile", destroyOnHide: true })(
  DeleteFileModal
);
