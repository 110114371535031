import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import { show } from "redux-modal";
import MerchantView from "../views/MainView/MerchantView";
import uploadFile from "../actions/uploadFile";
import getFiles from "../actions/getFiles";
import getYears from "../actions/getYears";
import { updateSelectedYear } from "../reducers/general";

interface Props {
  selectedYear: number;
  bioList: any[];
  matchList: { [id: string]: any };
  fileList: { [id: string]: any };
  merchantName: string;
  uploadFile: (file: File) => void;
  updateSelectedYear: (year?: number) => void;
  openModalDelete: any;
  openModalReport: any;
  getFiles: any;
  getYears: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      selectedYear: state.general.year.selectedYear,
      bioList: state.general.biostorage.bioList,
      matchList: state.general.match.matchList,
      fileList: state.files.fileList,
      merchantName: state.authentication.userInfo.merchant_name,
    }),
    (dispatch: any) => ({
      dispatch,
      uploadFile: (file: File) => dispatch(uploadFile(file)),
      getFiles: () => dispatch(getFiles()),
      getYears: () => dispatch(getYears()),
      updateSelectedYear: (year?: number) => dispatch(updateSelectedYear(year)),
      openModalReport: () =>
        dispatch(
          show("customerReport", {
            messageId: "merchantView.downloadReport.modal",
            type: "customerReport",
          })
        ),
      openModalDelete: () =>
        dispatch(
          show("deleteFile", {
            messageId: "merchantView.deleteFile.modal",
          })
        ),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      this.props.getFiles();
      this.props.getYears();
      this.props.updateSelectedYear();
    },
  })
)(MerchantView);
