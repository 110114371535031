import { BASE_URL } from "../api/endpoints";
import axios, { AxiosRequestConfig } from "axios";
import { show } from "redux-modal";
import { uploadSuccess, uploadError, uploadRequest } from "../reducers/files";
import getFileStatus from "./getFileStatus";
import { getToken } from "../common/utils";

export default (file: File) => {
  const token = getToken();
  const data = new FormData();
  data.append("xlxs_file", file);

  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    const selectedYear = state.general.year.selectedYear;
    data.append("tax_year", String(selectedYear));

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    let valid = false;
    let message = null;

    dispatch(uploadRequest());
    dispatch(
      show("file", {
        messageId: "merchantView.uploadFile.modal",
      })
    );
    try {
      const res = await axios.post(
        `${BASE_URL}/api/v1/sales_groups?merchant_id=${customer_id}`,
        data,
        config
      );
      var resData = res.data;
      if (resData.success && resData.sales_group_id) {
        dispatch(uploadSuccess(resData.sales_group_id));
        dispatch(getFileStatus(resData.sales_group_id));
        valid = true;
      }
    } catch (error) {
      message = "Network Connection Failed";
    }

    if (!valid) {
      if (!message) {
        if (resData && resData.errors && resData.errors.general)
          message = resData.errors.general;
        else if (resData) message = "Something unexpected happened";
      }
      dispatch(uploadError(message));
    }

    return Promise.resolve({
      valid: valid,
      message: message,
    });
  };
};
