import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";
import getBioProducts from "./getBioProducts";
import getMatchProducts from "./getMatchProducts";
import getCustomers from "./getCustomers";

export default () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";

    const fileList = Array.isArray(state.files.fileList)
      ? state.files.fileList
      : [];
    const selectedYear = state.general.year.selectedYear;

    let file = fileList.filter(
      (file: any) => file.tax_year === selectedYear
    )[0];

    let remainingFiles = fileList.filter(
      (file: any) => file.tax_year !== selectedYear
    );

    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/v1/sales_groups/${file.id}?merchant_id=${customer_id}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        types: [
          "FILE_DELETE_REQUEST",
          {
            type: "FILE_DELETE_SUCCESS",
            payload: (action: any, state: any, res: any) => {
              dispatch(getBioProducts());
              dispatch(getMatchProducts());
              dispatch(getCustomers());
              return remainingFiles;
            },
          },
          "FILE_DELETE_ERROR",
        ],
      },
    });
  };
};
