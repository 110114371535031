import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
    },
    box: {
      flex: 1,
      padding: theme.spacing(2),
      backgroundColor: "#D1E5D9",
      border: "1px solid rgba(1,130,59, 1)",
      borderRadius: 5,
    },
    yellow: {
      backgroundColor: "#ffeed7",
      border: "1px solid #ffac12",
    },
    red: {
      backgroundColor: "#ffdad1",
      border: "1px solid #ff441a",
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);

interface Props {
  isBio?: boolean;
  status: {
    isValid: boolean;
    isExtracting: boolean;
    isAdding: boolean;
  };
}

const InfoBox: React.FC<Props> = ({ isBio, status }) => {
  const classes = useStyles();

  const getTextBio = (status: {
    isValid: boolean;
    isExtracting: boolean;
    isAdding: boolean;
  }) => {
    if (!status.isValid)
      return (
        <div>
          <strong>
            <FormattedMessage id="merchantView.infoBox.bioStorage.not1" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.bioStorage.not2" />
        </div>
      );
    else if (status.isAdding && status.isExtracting)
      return (
        <div>
          <FormattedMessage id="merchantView.infoBox.bioStorage.volume" />{" "}
          <strong>
            <FormattedMessage id="merchantView.infoBox.bioStorage.added" />/
            <FormattedMessage id="merchantView.infoBox.bioStorage.extracted" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.bioStorage.from" />
        </div>
      );
    else if (status.isAdding)
      return (
        <div>
          <FormattedMessage id="merchantView.infoBox.bioStorage.volume" />{" "}
          <strong>
            <FormattedMessage id="merchantView.infoBox.bioStorage.added" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.bioStorage.to" />
        </div>
      );
    else if (status.isExtracting)
      return (
        <div>
          <FormattedMessage id="merchantView.infoBox.bioStorage.volume" />{" "}
          <strong>
            <FormattedMessage id="merchantView.infoBox.bioStorage.extracted" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.bioStorage.from" />
        </div>
      );
    else return null;
  };

  const getTextMatch = (status: {
    isValid: boolean;
    isExtracting: boolean;
    isAdding: boolean;
  }) => {
    if (!status.isValid || status.isExtracting || status.isAdding)
      return (
        <div>
          <FormattedMessage id="merchantView.infoBox.match.totalVolume" />{" "}
          <strong>
            <FormattedMessage id="merchantView.infoBox.match.notEqual" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.match.toVolume" />
        </div>
      );
    else
      return (
        <div>
          <FormattedMessage id="merchantView.infoBox.match.totalVolume" />{" "}
          <strong>
            <FormattedMessage id="merchantView.infoBox.match.equals" />
          </strong>{" "}
          <FormattedMessage id="merchantView.infoBox.match.theVolume" />
        </div>
      );
  };

  const textBio = getTextBio(status);
  const textMatch = getTextMatch(status);
  return (
    <div className={classes.root}>
      {isBio
        ? textBio && (
            <Box
              className={clsx(classes.box, {
                [classes.red]: !status.isValid,
              })}
            >
              {<Typography variant="body2">{textBio}</Typography>}
            </Box>
          )
        : textMatch && (
            <Box
              className={clsx(classes.box, {
                [classes.yellow]: status.isExtracting || status.isAdding,
                [classes.red]: !status.isValid,
              })}
            >
              <Typography variant="body2">{textMatch}</Typography>
            </Box>
          )}
    </div>
  );
};

export default InfoBox;
