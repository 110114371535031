import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Switch, Route, Redirect } from "react-router-dom";
import MerchantHeaderContainer from "../../containers/MerchantHeaderContainer";
import MerchantContainer from "../../containers/MerchantContainer";
import CustomerContainer from "../../containers/CustomerContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(4),
      minHeight: "100vh",
      width: "calc(100% - 250px)",
    },
  })
);

const MainView: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MerchantHeaderContainer />
      <Switch>
        <Route exact path="/" component={MerchantContainer} />
        <Route exact path="/customer" component={CustomerContainer} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default MainView;
