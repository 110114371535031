import { RSAA } from "redux-api-middleware";
import { BASE_URL } from "../api/endpoints";

export default () => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const customer_id = state.general.merchants.selectedMerchant
      ? state.general.merchants.selectedMerchant.customer_id
      : "";
    dispatch({
      [RSAA]: {
        endpoint: `${BASE_URL}/api/tax_years?merchant_id=${customer_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json" },
        types: ["YEAR_REQUEST", "YEAR_SUCCESS", "YEAR_ERROR"],
      },
    });
  };
};
