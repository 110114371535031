import { BASE_URL } from "../api/endpoints";
import {
  requestLogin,
  errorLogin,
  receiveLogin,
  successLogout,
} from "../reducers/authentication/index";
import { clearToken, isMerchant, isAuditor } from "../api/utils";
import { setToken, setTokenExpiration, getTokenData } from "../common/utils";
import getMerchants from "./getMerchants";
import { push } from "connected-react-router";

export const logout = () => {
  return async (dispatch: any) => {
    clearToken();
    dispatch(successLogout());
    dispatch(push("/login"));
    dispatch({
      type: "RESET",
    });
  };
};

export const login = (username: string, password: string) => {
  return async (dispatch: any) => {
    dispatch(requestLogin());
    let valid = false;
    let message = null;
    try {
      const response = await fetch(`${BASE_URL}/api/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_name: username,
          password: password,
        }),
      });
      var data = await response.json();
      if (
        data.success &&
        data.auth_token != null &&
        data.token_expiration != null
      ) {
        setToken(data.auth_token);
        setTokenExpiration(data.token_expiration);
        if (isMerchant() || isAuditor()) {
          dispatch(receiveLogin({ ...data, ...getTokenData() }));
          if (isAuditor()) dispatch(getMerchants());
          dispatch(push("/"));
          valid = true;
        } else {
          message =
            "You have to be a merchant or an operator in order to log in";
          clearToken();
        }
      }
    } catch (error) {
      message = "Network Connection Failed";
    }

    if (!valid) {
      if (!message) {
        if (data && data.error && data.error.user_authentication)
          message = data.error.user_authentication;
        else if (data) message = "Something unexpected happened";
      }
      dispatch(errorLogin(message));
    }

    return Promise.resolve({
      valid: valid,
      message: message,
    });
  };
};
