/**
 * File drag n drop & select component
 */

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import ButtonRounded from "./ButtonRounded";

interface DropZoneProps {
  handleFiles: (files: File[]) => void;
  disabled?: boolean;
}

const UploadButton: React.FC<DropZoneProps> = ({ handleFiles, disabled }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return disabled ? (
    <ButtonRounded
      fullWidth
      variant="contained"
      color="primary"
      disabled={true}
    >
      <FormattedMessage id="merchantView.uploadFileButton" />
    </ButtonRounded>
  ) : (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <ButtonRounded fullWidth variant="contained" color="primary">
        <FormattedMessage id="merchantView.uploadFileButton" />
      </ButtonRounded>
    </div>
  );
};

export default UploadButton;
