/**
 * Forgot username component
 */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles((theme: Theme) => ({
  icon: {
    marginTop: theme.spacing(0.3),
    marginRight: theme.spacing(0.5),
  },
  title: {
    fontSize: "11px",
  },
  popoverText: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    fontSize: "10px",
    lineHeight: "12px",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
    display: "block !important",
  },
  numbers: { paddingTop: 0, paddingBottom: 0, width: "50px" },
  popover: {
    pointerEvents: "none",
    "&>.MuiPaper-root": {
      maxWidth: "200px",
      overflowX: "visible",
      overflowY: "inherit",
      backgroundColor: theme.palette.grey[900],
      "&:after": {
        position: "absolute",
        content: "' '",
        top: "-5px",
        left: "50%",
        marginLeft: "-5px",
        width: "0",
        height: "0",
        display: "block !important",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: `5px solid ${theme.palette.grey[900]}`,
      },
    },
  },
}));

interface Props {
  productNumbers: number[];
}

const AlertPopover: React.FC<Props> = ({ productNumbers }) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let filteredProducts: any[] =
    productNumbers.length > 10
      ? productNumbers.filter(
          (product: number, index: number) => product.toString().length < 6
        )
      : productNumbers;

  if (filteredProducts.length > 59) {
    filteredProducts = filteredProducts.slice(0, 60);
    filteredProducts[59] = filteredProducts[59] + "...";
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        onMouseOver={handleOpen}
        onMouseOut={handleClose}
      >
        <Box className={classes.icon}>
          <WarningRoundedIcon color="error" />
        </Box>
        <Box flex={1}>
          <Typography variant="body2" color="error" className={classes.title}>
            <FormattedMessage id={"merchantView.uploadFile.unknownProducts"} />
          </Typography>
        </Box>
      </Box>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body2" className={classes.popoverText}>
          <FormattedMessage
            id={"merchantView.uploadFile.unknownProducts.text1"}
          />
        </Typography>
        <Box
          flex={1}
          flexWrap="wrap"
          style={{
            display: "flex",
          }}
        >
          {Array.isArray(filteredProducts) &&
            filteredProducts.map((productNumber) => (
              <Box>
                <Typography
                  variant="body2"
                  className={clsx(classes.popoverText, classes.numbers)}
                >
                  {productNumber}
                </Typography>
              </Box>
            ))}
        </Box>
        <Typography variant="body2" className={classes.popoverText}>
          <FormattedMessage
            id={"merchantView.uploadFile.unknownProducts.text2"}
          />
        </Typography>
      </Popover>
    </>
  );
};

export default AlertPopover;
