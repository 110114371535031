import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import getFiles from "../../../actions/getFiles";
import { updateSelectedYear } from "../../../reducers/general";
// State Interface
export interface MerchantsState {
  isRequesting: boolean;
  merchantList: any[];
  selectedMerchant: any;
  error?: any;
}

// Initial state
export const initialState = {
  merchantList: [],
  selectedMerchant: null,
  isRequesting: false,
  error: undefined,
} as MerchantsState;

// Actions
const MERCHANT_LIST_REQUEST = "MERCHANT_LIST_REQUEST";
const MERCHANT_LIST_ERROR = "MERCHANT_LIST_ERROR";
const MERCHANT_LIST_SUCCESS = "MERCHANT_LIST_SUCCESS";

const SELECT_MERCHANT = "SELECT_MERCHANT";

// Action creators
export function requestMerchants() {
  return {
    type: MERCHANT_LIST_REQUEST,
  };
}

export function errorMerchants(error: any) {
  return {
    type: MERCHANT_LIST_ERROR,
    payload: { error },
  };
}

export function receiveMerchants(data: any) {
  return {
    type: MERCHANT_LIST_SUCCESS,
    payload: data,
  };
}

export function changeSelectedMerchant(merchant: any) {
  return {
    type: SELECT_MERCHANT,
    payload: merchant,
  };
}

export function selectMerchant(merchant: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(changeSelectedMerchant(merchant));
    dispatch(getFiles());
    dispatch(updateSelectedYear());
  };
}

// Reducer Definition
export default handleActions<MerchantsState>(
  {
    [MERCHANT_LIST_REQUEST]: handleMerchantListRequest,
    [MERCHANT_LIST_ERROR]: handleMerchantListError,
    [MERCHANT_LIST_SUCCESS]: handleMerchantListSuccess,
    [SELECT_MERCHANT]: handleSelectMerchant,
  } as any,
  initialState
);

// Reducer Description
function handleMerchantListRequest(state: MerchantsState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleMerchantListError(
  state: MerchantsState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload,
  };
}

function handleMerchantListSuccess(state: MerchantsState, action: Action<any>) {
  return {
    ...state,
    merchantList: Array.isArray(action.payload.merchants)
      ? action.payload.merchants
      : [],
    selectedMerchant: state.selectedMerchant
      ? state.selectedMerchant
      : action.payload.merchants[0],
    isRequesting: false,
    error: undefined as any,
  };
}

function handleSelectMerchant(state: MerchantsState, action: Action<any>) {
  return {
    ...state,
    selectedMerchant: action.payload,
    isRequesting: false,
    error: undefined as any,
  };
}
