import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginContainer from "./containers/LoginContainer";
import SetNewPassView from "./views/SetNewPassView";
import Error404 from "./views/Error404";
import withRoot from "./withRoot";
import MainView from "./views/MainView";
import ReportModalContainer from "./containers/ReportModalContainer";
import FileModalContainer from "./containers/FileModalContainer";
import DeleteFileModalContainer from "./containers/DeleteFileModalContainer";

const App: React.FC = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route path="/set-new-password/:id" component={SetNewPassView} />
        <ProtectedRoute path="/" component={MainView} />
        <Route component={Error404} />
      </Switch>
      <ReportModalContainer />
      <FileModalContainer />
      <DeleteFileModalContainer />
    </div>
  );
};

export default withRoot(App);
