import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import FileModal from "../views/MainView/FileModal";

interface Props {
  show: boolean;
  handleHide: any;
  messageId?: string;
  loading?: boolean;
  error?: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      loading: state.files.isRequesting,
      error: state.files.error,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(FileModal);
