import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import MerchantHeaderView from "../views/MainView/Header";
import { logout } from "../actions/authentication";
import { setLanguage } from "../reducers/profile";

interface Props {
  logout: any;
  language: string;
  setLanguage: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({}),
    (dispatch: any) => ({
      dispatch,
      logout: () => dispatch(logout()),
      setLanguage: (lang: string) => dispatch(setLanguage(lang)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(MerchantHeaderView);
