/**
 *  A modal template component for building consistent modals / dialogs
 */

import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { connectModal } from "redux-modal";
import { FormattedMessage } from "react-intl";
import Box from "@material-ui/core/Box";
import DownloadButton from "./CustomerReport";
import registerFonts from "./CustomerReport/components/config/registerFonts";
import getCustomerReport from "../../api/getCustomerReport";
import getEndCustomerReport from "../../api/getEndCustomerReport";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import RequiredAsterisk from "../../components/texts/RequiredAsterisk";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

registerFonts();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "500px",
      minHeight: "300px",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(6),
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1.25),
      left: theme.spacing(1.25),
      color: theme.palette.grey[900],
    },
    buttons: {
      marginTop: theme.spacing(4),
      backgroundColor: theme.palette.background.paper,
      zIndex: 10,
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
    red: {
      color: "red",
    },
    circularProgress: {
      position: "absolute",
      width: "48px",
      height: "48px",
      zIndex: 0,
    },
  })
);

interface Props {
  show: boolean;
  handleHide: any;
  selectedYear?: number;
  merchant?: any;
  customer?: any;
  messageId?: string;
  type?: string;
  loading?: boolean;
  error?: any;
}

const ReportModal: React.FC<Props> = ({
  show,
  handleHide,
  selectedYear,
  merchant,
  customer,
  messageId,
  type,
}) => {
  const classes = useStyles();
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const onSubmit = async (values: any) => {
    setIsError(false);
    //setIsLoading(true);
    /* getCustomerPDFFiles(selected_customer.id, {
      customer_name: values.customerName,
      tax_year: values.taxYear,
      name: values.name,
      position: values.position,
      date: "31-10-2020", //hard coded
    }); */
  };

  let getReport: any = null;
  let paramData: {
    customer_name: string;
    tax_year?: number;
    merchant_name?: string;
    customer_no?: string;
  } = { customer_name: "" };

  if (type === "customerReport") {
    getReport = getCustomerReport;
    paramData = {
      customer_name: merchant.merchant_name,
      tax_year: selectedYear,
    };
  }
  if (type === "endCustomerReport") {
    getReport = getEndCustomerReport;
    paramData = {
      merchant_name: merchant.merchant_name,
      customer_name: customer.customer_name,
      customer_no: customer.customer_no,
      tax_year: selectedYear,
    };
  }

  const fetchReportData = async () => {
    if (!getReport) return;
    const res = await getReport(merchant.user_id, paramData);

    if (res && res.valid) {
      setData(res.data);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  const required = (value: any) =>
    value && value !== "" ? undefined : "Required";

  return (
    <Dialog open={show} onClose={handleHide}>
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={handleHide}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {messageId && <FormattedMessage id={messageId.concat(".title")} />}
        </Typography>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {!data && <CircularProgress className={classes.circularProgress} />}
          {data && (
            <Form
              onSubmit={onSubmit}
              initialValues={{
                customerName: paramData.customer_name,
                name: "",
                position: "",
                taxYear: selectedYear,
                date: `${selectedYear}/03/31`,
                language: "",
                version: "",
              }}
              render={({
                handleSubmit,
                submitting,
                pristine,
                hasValidationErrors,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} className={classes.marginBottom}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.labelName}
                      >
                        Customer Name
                      </Typography>
                      <Field name="customerName">
                        {(props) => (
                          <TextField
                            {...props.input}
                            variant="outlined"
                            disabled
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                    {/* user choose language for the download pdf report */}
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.labelName}
                      >
                        Report Language <RequiredAsterisk />
                      </Typography>
                      <Field
                        name="language"
                        component="select"
                        validate={required}
                      >
                        {({ input, meta }) => (
                          <div>
                            <Select
                              {...input}
                              name="language"
                              value={input.value}
                              onChange={input.onChange}
                              input={<OutlinedInput labelWidth={0} fullWidth />}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Norsk">Norsk</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                            </Select>
                          </div>
                        )}
                      </Field>
                    </Grid>

                    {/* users choose between two reports version */}
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        className={classes.labelName}
                      >
                        Report Version <RequiredAsterisk />
                      </Typography>
                      <Field
                        name="version"
                        component="select"
                        validate={required}
                      >
                        {({ input, meta }) => (
                          <div>
                            <Select
                              {...input}
                              name="versuib"
                              value={input.value}
                              onChange={input.onChange}
                              input={<OutlinedInput labelWidth={0} fullWidth />}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Simple">Simple Version</MenuItem>
                              <MenuItem value="Full">Full Version</MenuItem>
                            </Select>
                          </div>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  {values.language && values.version === "Full" && (
                    <DownloadButton
                      type={type}
                      language={values.language === "English" ? "EN" : "NO"}
                      version={"Full"}
                      data={{ ...paramData, ...data }}
                    ></DownloadButton>
                  )}
                  {values.language && values.version === "Simple" && (
                    <DownloadButton
                      type={type}
                      language={values.language === "English" ? "EN" : "NO"}
                      version={"Simple"}
                      data={{ ...paramData, ...data }}
                    ></DownloadButton>
                  )}
                </form>
              )}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default connectModal({ name: "customerReport", destroyOnHide: true })(
  ReportModal
);
